import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axiosConfig from "../ApiCall/axiosConfig";

// import i18n from "i18next";
import cookies from "js-cookie";
import Navbar from "../Components/Navbar";
import SoftwareMain from "../Components/SoftwareMain";
const languages = [
  { code: "en", name: "English", dir: "ltr" },
  { code: "fa", name: "فارسی" },
];

const Home = () => {
  const currentLanguageCode = cookies.get("i18next") || "fa";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  const { t } = useTranslation();
  const [data, setData] = useState([]);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "rtl";
    document.title = t("app_title");
    const token = localStorage.getItem("tokenDeliveryCompanyRoysa");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    axiosConfig
      .get("/company_menu/list", config)
      .then(function (response) {
        setData({ menu: response.data.data });
      })
      .catch(function (error) {
        console.log("in start error ");
        console.log(error.code);
        console.log("in end error ");
      });
  }, [currentLanguage, t]);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Navbar menu={data.menu} toggle={toggle} />
      {/* <h6>{t("days_since_release", { number_of_days })}</h6>
      <span>{t("language")}</span>
      {languages.map(({ code, name }) => (
        <button
          onClick={() => i18n.changeLanguage(code)}
          style={{ height: "5vh" }}
          disabled={code === currentLanguageCode}
        >
          {name}
        </button>
      ))} */}
      <SoftwareMain />
    </>
  );
};

export default Home;
