import React, { Component, useEffect, useState } from "react";
import {
  Container,
  ListWrapper,
  SearchWrapper,
} from "../../Components/ContainerSoftware/ContainerElements";

const Products = () => {
  const [items, setItems] = useState([]);
  const [searchItems, setSearchItems] = useState([]);
  // const [loading, setLoading] = useState(false);

  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      let results = searchItems.filter((item) => {
        return item?.title.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setItems(results);
    } else {
      setItems(searchItems);
    }
  };

  return (
    <>
      <Container>
        <SearchWrapper></SearchWrapper>
        <ListWrapper>این صفحه در حال راه اندازی می باشد ...</ListWrapper>
      </Container>
    </>
  );
};

export default Products;
