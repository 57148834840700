import styled from "styled-components";

export const MainContainer = styled.aside`
  width: 95.4vw;
  height: 100vh;
  padding-inline-end: 1vw;
`;
export const Wrapper = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-auto-columns: 100%;
  grid-template-rows: auto;
  align-items: start;
  grid-template-areas:
    "col1"
    "col2";
`;
