import React, { useState, useEffect, useRef } from "react";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavMenue,
  NavItem,
  NavLink,
  Logo,
  NavIcon,
  NavLogOut,
  LogOutIcon,
  NavTitle,
  PTitle,
} from "./NavbarElements";
import logo from "../../Images/logo.svg";
import enLogo from "../../Images/enLogo.svg";
import logOut from "../../Images/logOut.svg";
import Cookies from "js-cookie";
const Navbar = ({ menu }) => {
  const [menus, setMenus] = useState([]);
  const [activeMenuId, setActiveMenuId] = useState(0);
  const [hoverMenuId, setHoverMenuId] = useState(0);
  const firstNavLinkRef = useRef(null);
  const currentLanguageCode = Cookies.get("i18next") || "fa";

  useEffect(() => {
    if (menu) {
      setMenus(menu);
    }
    // Check if firstNavLinkRef is defined and click it
    if (firstNavLinkRef.current) {
      firstNavLinkRef.current.click();
    }
  }, [menu, menus]);
  const changeActive = (menu) => {
    setActiveMenuId(menu?.id);
  };
  const startHover = (menu) => {
    setHoverMenuId(menu?.id);
  };
  const endHover = (menu) => {
    setHoverMenuId(0);
  };
  const handleLogOut = () => {
    localStorage.setItem("tokenDeliveryCompanyRoysa", "");
  };

  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo to="/">
            <Logo src={currentLanguageCode === "fa" ? logo : enLogo} />
          </NavLogo>
          <NavMenue>
            {menus &&
              menus.map((menu, index) => (
                <NavItem key={menu.id}>
                  <NavLink
                    ref={index === 0 ? firstNavLinkRef : null} // Assign the ref to the first NavLink
                    to={menu.url}
                    state={menu}
                    active={menu.id === activeMenuId}
                    onMouseEnter={() => startHover(menu)}
                    onMouseLeave={() => endHover(menu)}
                    onClick={() => {
                      changeActive(menu);
                    }}
                  >
                    <NavIcon
                      active={menu.id === activeMenuId}
                      src={process.env.PUBLIC_URL + menu.icon}
                    ></NavIcon>
                    <NavTitle
                      position={
                        (index === 0 && "START") ||
                        (index === menus.length - 1 && "END")
                      }
                      hover={menu.id === hoverMenuId ? true : false}
                    >
                      <PTitle>{menu?.title}</PTitle>
                    </NavTitle>
                  </NavLink>
                </NavItem>
              ))}
          </NavMenue>
          <NavLogOut onClick={handleLogOut} to="/login">
            <LogOutIcon src={logOut}></LogOutIcon>
          </NavLogOut>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
