import Joi from "joi";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import axiosConfig from "../../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";

import {
  Body,
  Container,
  Header,
  TitleHead,
  DivItems,
  Btn,
  BtnDiv,
  DivIconHead,
  IconHead,
  ListItems,
  DivHeader,
  Lable,
  TitleBtn,
  Detail,
  Admin,
  DivAdmin,
  IconDetail,
  DivList,
  IconOperationHead,
} from "./OperationsElement";
import icon from "../../../Images/headerIcons/roles.svg";
import editIcon from "../../../Images/editIcon.svg";
import deleteIcon from "../../../Images/deleteIcon.svg";
import adminIcon from "../../../Images/adminIcon.svg";

import { useTranslation } from "react-i18next";
import {
  DashedSubCategories,
  DivBoxSubCategories,
  DivCategories,
  DivCategory,
  DivSubCategories,
  DivSubCategory,
} from "./Element";
// import AddRoleForm from "../../Form/AddRoleForm";

const Operations = ({
  showModalOperations,
  handleAddNew,
  handleCloseModal,
  handleOpenModal,
  detailItem,
  handleResetUpdateModal,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [item, setItem] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    console.log(detailItem);
    setShowModal(showModalOperations);
    setItem(detailItem);
  }, [detailItem, showModalOperations]);
  const CloseModal = () => {
    handleCloseModal("modalOperations");
  };
  const handleUpdate = () => {
    handleCloseModal("modalOperations");
    handleOpenModal("modalUpdate", detailItem);
  };
  const handleDelete = () => {
    handleCloseModal("modalOperations");
    handleOpenModal("modalDelete");
  };

  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={CloseModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <Container>
          <Header>
            <DivHeader>
              <DivIconHead>
                <IconHead
                  haveLogo={item?.logo ? true : false}
                  src={item?.logo ? item?.logo : icon}
                />
              </DivIconHead>
              <TitleHead>{item?.persian_title}</TitleHead>
            </DivHeader>
            <BtnDiv btnPadding={true}>
              <Btn operation="EDIT" onClick={handleUpdate}>
                <IconOperationHead src={editIcon} />
                <TitleBtn>{t("edit")}</TitleBtn>
              </Btn>
              <Btn operation="DELETE" onClick={handleDelete}>
                <IconOperationHead src={deleteIcon} />
                <TitleBtn>{t("delete")}</TitleBtn>
              </Btn>
            </BtnDiv>
          </Header>
          <Body>
            <DivItems>
              <Lable>دسترسی های مجاز</Lable>
              <ListItems>
                <DivList>
                  {item?.permissions?.map(
                    (category) =>
                      !category.parent && (
                        <DivCategories key={category?.id}>
                          <DivCategory>{category?.persian_title}</DivCategory>
                          <DivBoxSubCategories>
                            <DashedSubCategories />
                            <DivSubCategories>
                              {item?.permissions?.map(
                                (subCategory) =>
                                  subCategory?.parent === category.title && (
                                    <DivSubCategory key={subCategory?.id}>
                                      {subCategory?.persian_title}
                                    </DivSubCategory>
                                  )
                              )}
                            </DivSubCategories>
                          </DivBoxSubCategories>
                        </DivCategories>
                      )
                  )}
                </DivList>
              </ListItems>
            </DivItems>
          </Body>
        </Container>
      </Modal>
    </>
  );
};

export default Operations;
