import styled from "styled-components";
export const Container = styled.div`
  height: 6vh;
  width: 63vw;
  border-radius: 1.6vh;
  margin-top: -1vh;

  position: relative;
`;
export const SearchBox = styled.input`
  position: absolute;
  z-index: 10;
  padding-inline-start: 3.4vw;
  padding-inline-end: 1.2vw;
  font-size: 0.9vw;
  font-weight: 600;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 0.5vw 0.5vw 1.3vw 1.3vw;
  border: 0.12vw solid #f9e9be;
  margin: 0;
  &:focus {
    background: #fffcf5;
  }
`;
export const SearchIcon = styled.img`
  position: absolute;
  z-index: 11;
  top: 0;
  marker-start: 0;
  padding-inline-start: 0.4vw;
  height: 100%;
`;
