import Joi from "joi";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import axiosConfig from "../../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";

import {
  Body,
  Container,
  Header,
  TitleHead,
  DashedSubPermissions,
  DivBoxSubPermissions,
  DivPermission,
  DivPermissions,
  DivSelect,
  DivSubPermission,
  DivSubPermissions,
  Input,
  DivInput,
  Lable,
  Btn,
  BtnDiv,
  ActivRoleCheckbox,
  DivIconHead,
  IconHead,
} from "./Element";
import icon from "../../../Images/headerIcons/roles.svg";
const Add = ({ lastId, showAddModal, handleAddNew, handleCloseModal }) => {
  const [data, setData] = useState({
    company_id: parseInt(localStorage.getItem("companyId")),
    persian_title: "",
    title: "Admin" + (lastId + 1),
    permissions: [],
    parent: null,
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [permissionsArray, setPermissionsArray] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setShowModal(showAddModal);
    setLoading(true);
    setData({
      company_id: parseInt(localStorage.getItem("companyId")),
      persian_title: "",
      title: "Admin" + (lastId + 1),
      permissions: [],
      rank: 10,
      status: 1,
    });
    setErrors({});
    async function fetchPermissions() {
      const token = localStorage.getItem("tokenDeliveryCompanyRoysa");
      const params = new URLSearchParams([
        ["per_page", 100],
        ["page", 1],
      ]);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data: permissionsData } = await axiosConfig.get(
          "/company_permission/list",
          config
        );
        const data = permissionsData.data.data.map((permission) => ({
          id: permission.id,
          title: permission.title,
          persian_title: permission.persian_title,
          parent: permission.parent,
          active: false,
        }));
        setPermissionsArray(data);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching permissions:", error);
      }
    }
    fetchPermissions();
  }, [lastId, showAddModal]);

  const schema = Joi.object({
    company_id: Joi.number().integer().required(),
    persian_title: Joi.string().required(),
    title: Joi.string().optional(),
    permissions: Joi.array().min(1).required(),
    parent: [Joi.string().optional(), Joi.allow(null)],
    rank: [Joi.number().integer().required(), Joi.allow(null)],
    status: [Joi.number().integer().required(), Joi.allow(null)],
  });
  const CloseModal = () => {
    handleCloseModal("addModal");
  };
  const selectPermission = (permission) => {
    const updatedPermissionsArray = permissionsArray.map((dataItem) => {
      if (permission.id === dataItem.id) {
        return {
          ...dataItem,
          active: !dataItem.active,
        };
      } else if (permission.title === dataItem.parent) {
        return {
          ...dataItem,
          active: !permission.active,
        };
      } else {
        return dataItem;
      }
    });

    setPermissionsArray(updatedPermissionsArray);

    // Update data.permissions based on updatedPermissionsArray
    const selectedPermissions = updatedPermissionsArray
      .filter((item) => item.active)
      .map((item) => item.title);
    setData({ ...data, permissions: selectedPermissions });
  };
  const selectSubPermission = (subPermission) => {
    const updatedSubPermissionsArray = permissionsArray.map((dataItem) => {
      if (subPermission.id === dataItem.id) {
        return {
          ...dataItem,
          active: !dataItem.active,
        };
      } else if (subPermission.parent === dataItem.title) {
        return {
          ...dataItem,
          active: true,
        };
      } else {
        return dataItem;
      }
    });

    setPermissionsArray(updatedSubPermissionsArray);

    // Update data.permissions based on updatedSubPermissionsArray
    const selectedPermissions = updatedSubPermissionsArray
      .filter((item) => item.active)
      .map((item) => item.title);
    setData({ ...data, permissions: selectedPermissions });
  };

  const handleChange = (e) => {
    setData({ ...data, [e.currentTarget.id]: e.currentTarget.value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validate();
    setErrors({ errors: errors || {} });
    if (errors) return;
    doSubmit();
  };
  const validate = () => {
    const result = schema.validate(data, { abortEarly: false });
    let errorMessage = "";
    if (!result.error) return null;
    if (result.error.details[0].path[0] === "persian_title") {
      errorMessage = "عنوان نمی تواند خالی باشد";
    } else if (result.error.details[0].path[0] === "permissions") {
      errorMessage = "حداقل یک سطح دسترسی باید انتخاب شود";
    }
    toast.warning(errorMessage);
    console.log(result.error);
    const errors = {};
    for (const item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const doSubmit = async () => {
    let isOkResponse;
    const toastId = toast.loading("در حال ایجاد نقش ...");
    const token = localStorage.getItem("tokenDeliveryCompanyRoysa");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      await axiosConfig.post("/company_role/store", data, config);
      isOkResponse = true;
    } catch (error) {
      isOkResponse = false;
      console.log("Error response from server:", error.response.data.status);
      console.log(errors);
      toast.update(toastId, {
        render: "خطا در اتصال به سرور",
        type: "error",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
    }
    if (isOkResponse) {
      handleAddNew(toastId);
      CloseModal();
    }
  };

  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={CloseModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <Container>
          <Header>
            <DivIconHead>
              <IconHead src={icon} />
            </DivIconHead>
            <TitleHead>تعریف نقش جدید</TitleHead>
          </Header>
          <Body>
            <DivInput>
              <Input
                placeholder="عنوان نقش"
                id="persian_title"
                onChange={handleChange}
                value={data.persian_title}
              />
            </DivInput>
            <DivInput>
              <Lable>اختصاص دسترسی</Lable>
              <DivSelect>
                {loading ? (
                  <div className="loadingList">
                    <div className="spinner"></div>
                  </div>
                ) : (
                  permissionsArray?.map((permission, indexPermission) =>
                    permission.parent === null ? (
                      <DivPermissions key={permission.id}>
                        <DivPermission>
                          <ActivRoleCheckbox
                            active={permission.active}
                            onClick={() => {
                              selectPermission(permission);
                            }}
                          />
                          {permission.persian_title}
                        </DivPermission>
                        <DivBoxSubPermissions>
                          <DashedSubPermissions />
                          <DivSubPermissions>
                            {permissionsArray?.map((subPermission, index) =>
                              subPermission.parent === permission.title ? (
                                <DivSubPermission key={subPermission.id}>
                                  <ActivRoleCheckbox
                                    active={subPermission.active}
                                    onClick={() => {
                                      selectSubPermission(subPermission);
                                    }}
                                  />
                                  {subPermission.persian_title}
                                </DivSubPermission>
                              ) : null
                            )}
                          </DivSubPermissions>
                        </DivBoxSubPermissions>
                      </DivPermissions>
                    ) : null
                  )
                )}
              </DivSelect>
            </DivInput>
            <BtnDiv btnPadding={true}>
              <Btn okBtn onClick={handleSubmit}>
                ایجاد
              </Btn>
              <Btn onClick={CloseModal}>انصراف</Btn>
            </BtnDiv>
          </Body>
        </Container>
      </Modal>
    </>
  );
};

export default Add;
