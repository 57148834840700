import React from "react";
import { useLocation } from "react-router";
import ContainerSoftware from "../../Components/ContainerSoftware";
import TabsComponent from "../../Components/Tabs";

const Index = () => {
  const location = useLocation();
  return (
    <>
      <TabsComponent menu={location.state}></TabsComponent>
      <ContainerSoftware></ContainerSoftware>
    </>
  );
};

export default Index;
