import styled from "styled-components";
export const Container = styled.div`
  height: 97%;
  width: 88vw;
  border-radius: 1.6vh;
  display: flex;
  align-content: flex-start;
  column-gap: 3%;
  row-gap: 2vh;
  flex-wrap: wrap;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const Item = styled.div`
  font-size: 0.8vw;
  width: 31%;
  position: relative;
  padding-inline-start: 3.4vw;
`;
export const Card = styled.img`
  height: 100%;
  width: 100%;
`;
export const DetailItem = styled.div`
  position: absolute;
  inset-inline-start: 8.9vw;
  top: 2.1vw;
  display: flex;
  column-gap: 1.4vw;
  align-items: flex-start;
  flex-direction: column;
`;
export const CoverImg = styled.div`
  position: absolute;
  inset-inline-start: 0.2vw;
  top: 0.7vw;
  height: 6.6vw;
  width: 6.6vw;
  background: white;
  border-radius: 1vw;
`;
export const Img = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 1vw;
`;
export const Title = styled.p`
  font-size: 1vw;
  font-weight: 700;
  padding-inline-end: 1.3vw;
`;
export const Description = styled.p`
  font-size: 0.76vw;
  font-weight: 600;
  padding-inline-end: 1.3vw;
  padding-top: 0.8vw;
  color: var(--gray);
`;
export const Price = styled.p`
  font-size: 1vw;
  font-weight: 900;
  padding-top: 1.2vw;
  color: var(--orange);
`;
export const Unit = styled.span`
  font-size: 0.8vw;
  font-weight: 700;
  padding-inline-start: 0.4vw;
  color: black;
`;
export const Btn = styled.button`
  position: absolute;
  inset-inline-end: 0.7vw;
  bottom: 1.2vw;
  height: 2.7vw;
  width: 2.7vw;
  border-radius: 1vw;
  border: none;
  margin-right: auto;
  background: var(--orange);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: var(--lightBrown);
  }
`;
export const Icon = styled.img`
  height: 100%;
  width: 100%;
`;
export const BtnAdd = styled.button`
  position: absolute;
  inset-inline-start: 1vw;
  bottom: 1.8vh;
  height: 2.8vw;
  border-radius: 1vw;
  border: none;
  background: var(--brown);
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-inline-end: 1.3vw;
  font-weight: 600;
  font-size: 0.9vw;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: black;
  }
`;
export const IconBtnAdd = styled.img`
  height: 100%;
`;
