import styled from "styled-components";
import { Link } from "react-router-dom";
export const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 4.6vw;
  position: relative;
`;
export const NavbarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;
export const NavLogo = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  padding-inline-start: 0.8vw;
  padding-inline-end: 0.2vw;
  padding-top: 3.2vh;
`;
export const Logo = styled.img`
  width: 100%;
  height: 100%;
`;
export const NavLogOut = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  margin-inline: 0.6vw;
  margin-bottom: 2vh;
  border-radius: 0.4vw;
  transition: all 0.3s ease-in-out;
  &:hover {
    border-radius: 50%;
    background: linear-gradient(
      180deg,
      var(--hoverMenuBackgroundTop) 0%,
      var(--hoverMenuBackgroundBottom) 100%
    );
  }
`;
export const LogOutIcon = styled.img`
  width: 100%;
  height: 100%;
`;
export const NavMenue = styled.ul`
  display: flex;
  text-align: center;
  align-items: flex-start;
  list-style: none;
  flex-direction: column;
  padding: 0;
  width: 6.4vw;
  margin-inline-start: 0.6vw;
  max-height: 64vh;
  overflow-x: hidden;
  z-index: 1;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const NavItem = styled.li`
  display: flex;
  text-align: center;
  width: 3.4vw;
  margin-bottom: 0.4vh;
`;
export const NavLink = styled(Link)`
  width: 100%;
  display: flex;
  height: 3.4vw;
  border-radius: 0.4vw;
  text-decoration: none;
  align-items: start;
  text-align: center;
  transition: 0.6s ease-in-out;
  position: relative;

  ${({ active }) =>
    active
      ? "background: linear-gradient(180deg,var(--activeMenuBackgroundTop) 0%,var(--activeMenuBackgroundBottom) 100%);cursor: default;"
      : "cursor: pointer;"};

  &:hover {
    ${({ active }) =>
      !active
        ? "background: linear-gradient(180deg,var(--hoverMenuBackgroundTop) 0%,var(--hoverMenuBackgroundBottom) 100%);"
        : ""};
  }
`;
export const NavTitle = styled.div`
  visibility: ${({ hover }) => (hover ? "visible" : "hidden")};
  display: flex;
  align-items: center;
  position: absolute;
  inset-inline-start: 4.3vw;
  height: 3.4vw;
  z-index: 100;
  align-items: ${({ position }) =>
    (position === "START" && "flex-start") ||
    (position === "END" && "flex-end")};
`;
export const PTitle = styled.p`
  font-size: 0.8vw;
  font-weight: 600;
  color: var(--darkOrange);
  transform: rotate(-180deg);
  display: inline-block;
  white-space: nowrap;
  writing-mode: tb;
`;
export const NavPointer = styled.div`
  opacity: ${({ active }) => (active ? "100%" : "0")};
  width: 0.4vw;
  height: 88%;
  margin: auto 0;
  border-radius: 1vh 0 0 1vh;
  background-color: #cad3d8;
  display: flex;
  text-align: center;
`;
export const NavIcon = styled.img`
  ${({ active }) => (active ? "filter: brightness(0) invert(1);" : "")};

  width: 100%;
`;
