import styled from "styled-components";
export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #feca31 0%, #f0b300 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
export const BoxLogin = styled.div`
  width: 26vw;
  height: 60vh;
  display: flex;
  flex-direction: column;
  margin-top: -20vh;
`;
export const BoxOrganization = styled.div`
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const LogoOrganization = styled.img`
  width: 9vw;
`;
export const BoxInputLogin = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  background: rgba(255, 255, 255, 0.5);
  border: 0.1vw solid white;
  border-radius: 1.4vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 3vh;
`;
export const IconInput = styled.img`
  position: absolute;
  inset-inline-start: 0;
  width: 3.2vw;
`;
export const DivInput = styled.div`
  width: 19.4vw;
  height: 6vh;
  display: flex;
  flex-direction: column;
`;
export const DivInputUsername = styled.div`
  position: relative;
  height: 6vh;
  width: 100%;
  display: flex;
  align-items: center;
`;
export const Input = styled.input`
  padding-inline-start: 3.2vw;
  padding-inline-end: 1.2vw;
  font-size: 0.9vw;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 1.6vh;
  border: 0.11vw solid var(--inputStrokeLogin);
  margin: 0;
`;
export const DivInputPassword = styled.div`
  position: relative;
  height: 6vh;
  width: 100%;
  display: flex;
  align-items: center;
`;
export const InputPassword = styled.input`
  padding-inline-start: 3.2vw;
  padding-inline-end: 2.8vw;
  font-size: 0.9vw;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 1.6vh;
  border: 0.11vw solid var(--inputStroke);
  margin: 0;
`;
export const BtnShowPassword = styled.button`
  height: 100%;
  background: none;
  font-size: 1.7vh;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 0;
  inset-inline-end: 0.4vw;
  padding: 0 0.5vw;
`;
export const ImgShowPassword = styled.img`
  height: 50%;
`;
export const Btn = styled.button`
  width: 100%;
  height: 6vh;
  margin-top: 1vh;
  font-size: 1vw;
  border-radius: 1.6vh;
  align-items: center;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  background: var(--brown);
  font-weight: 600;
  color: white;
  text-decoration: none;
  padding: 1.5vh 1vw;
  transition: all 0.2s ease-in-out;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: black;
    color: white;
  }
`;
export const Footer = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding-bottom: 3vh;
`;
export const DivFooterTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 1vh;
  color: white;
  background: rgba(0, 0, 0, 0.2);
  height: 1.2vh;
  padding-bottom: 0.2vh;
  padding-inline: 1.8vw;
  border-radius: 10vw;
`;
export const FooterTitle = styled.p`
  font-family: "Time New Roman" !important;
  font-weight: 600;
  font-size: 1.5vw;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  opacity: 76%;
`;
