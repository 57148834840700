import styled from "styled-components";
export const Container = styled.div`
  ${({ UnPaddingStart }) =>
    UnPaddingStart
      ? "padding-block:3vh;padding-inline-end:1.7vw;"
      : "padding: 3vh 1.7vw;"}
  height: 100%;
  width: 100%;
  border-radius: 1.6vh;
  display: flex;
  flex-direction: column;
  column-gap: 3%;
  row-gap: 2vh;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const DivItems = styled.div`
  width: 100%;
  row-gap: 2vh;

  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const Item = styled.div`
  font-size: 0.8vw;
  width: 100%;
  padding: 0.7vw;

  border-radius: 1vw;
  display: flex;
  align-items: center;

  transition: all 0.2s ease-in-out;

  ${({ active }) =>
    active
      ? "background: linear-gradient(45deg, var(--activeCompanyRequestBackgroundStart) 0%, var(--activeCompanyRequestBackgroundEnd) 100%);;"
      : "background: white;cursor: pointer;&:hover {background: var(--hoverBackgroundCompanyRequest);}"}
`;
export const Card = styled.img`
  height: 100%;
  width: 100%;
`;
export const DetailItem = styled.div`
  width: 100%;
  height: 100%;
  padding: 3vh 1.7vw;
  display: flex;
  column-gap: 1.4vw;
  align-items: flex-start;
  flex-direction: column;
  background: white;
  border-radius: 0.8vw;
  position: relative;
`;
export const ImgDefault = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 0.8vw;
  top: 0;
  inset-inline-start: 0;
  position: absolute;
  object-fit: cover;
`;
export const CoverImg = styled.div`
  height: 3.8vw;
  min-width: 3.8vw;
  max-width: 3.8vw;
  border-radius: 0.7vw;
  background: var(--orange);
`;

export const Img = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 0.7vw;
  ${({ isLogo }) => (isLogo ? "" : "filter: brightness(0) invert(1);")}
`;
export const ImgStatus = styled.img`
  height: 3vh;
`;
export const Div = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Detail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-inline-start: 1vw;
`;
export const Title = styled.p`
  font-size: 0.94vw;
  font-weight: 700;
  padding-inline-end: 1.3vw;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 14vw;
  color: ${({ active }) => (active ? "white" : "var(--brown)")};
`;
export const TextNoRequest = styled.p`
  font-size: 0.94vw;
  font-weight: 700;
  color: var(--lable);
  text-align: center;
  margin-top: 30vh;
`;
export const Time = styled.div`
  display: flex;
  font-size: 0.9vw;
  font-weight: 800;
  color: ${({ status, activePending }) =>
    (status === "PENDING" && "var(--orange)") ||
    (status === "AGREE" && "var(--green)") ||
    (status === "DENY" && "var(--red)") ||
    (status === "INVALID" && "var(--lable)") ||
    (activePending === "ACTIVE_PENDING" && "black")};
`;
export const StatusDiv = styled.div`
  width: 1.4vw;
  height: 1.4vw;
  border-radius: 50%;
  margin-inline-start: 0.3vw;
  background: ${({ status }) =>
    (status === "AGREE" && "var(--green)") ||
    (status === "DENY" && "var(--red)") ||
    (status === "INVALID" && "var(--lable)")};
`;

export const Description = styled.p`
  font-size: 0.76vw;
  font-weight: 600;
  padding-inline-end: 1.3vw;
  padding-top: 0.6vw;
  color: ${({ active }) => (active ? "var(--brown)" : "var(--gray)")};
`;
export const Price = styled.p`
  font-size: 1vw;
  font-weight: 900;
  padding-top: 1.2vw;
  color: var(--orange);
`;
export const Icon = styled.img`
  height: 100%;
  width: 100%;
`;
export const Btn = styled.button`
  width: 46%;
  font-size: 0.9vw;
  border-radius: 1.6vh;
  align-items: center;
  text-align: center;
  color: white;
  display: flex;
  justify-content: flex-start;
  background: ${({ operation }) =>
    (operation === "ACCEPT" && "var(--green)") ||
    (operation === "REJECT" && "var(--red)")};
  font-weight: 600;
  color: white;
  text-decoration: none;
  padding-inline: 0.3vw;
  height: 3.2vw;
  transition: all 0.2s ease-in-out;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: ${({ operation }) =>
      (operation === "ACCEPT" && "var(--darkGreen)") ||
      (operation === "REJECT" && "var(--darkRed)")};
    color: white;
  }
`;
export const Footer = styled.div`
  padding-top: 3vh;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
export const DivResponse = styled.div`
  width: 100%;
  font-size: 0.94vw;
  font-weight: 800;
  display: flex;
  ${({ reject }) =>
    reject
      ? "background: var(--backgroundBoxRoysaRejectResponseToCompanies);color:var(--red);"
      : "background: var(--backgroundBoxRoysaAcceptResponseToCompanies);color:black;"}
  padding: 1.6vh 1vw;
  border-radius: 0.7vw;
`;
export const Span = styled.span`
  color: var(--green);
`;
export const BtnDiv = styled.div`
  width: 19.4vw;
  display: flex;
  flex-direction: ${({ rowReverse }) => (rowReverse ? "row-reverse" : "row")};
  align-items: flex-end;
  justify-content: space-between;
`;
export const BtnIcon = styled.img`
  height: 100%;
`;
export const TitleBtn = styled.p`
  width: 3.8vw;
`;
export const IconBtnAdd = styled.img`
  height: 100%;
`;
export const DivFilters = styled.div`
  min-height: 3vw;
  width: 100%;
  border-radius: 0.8vw;
  display: flex;
  justify-content: start;
  gap: 1vw;
  flex-direction: row;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const Btnfilter = styled.button`
  height: 3vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 0.8vw;
  border: none;
  ${({ active }) =>
    active
      ? "background: var(--red);color: white;"
      : "background: white;color:var(--disabledColorFilter);cursor: pointer;&:hover {background: var(--hoverBackgroundFilter);}"}
  display: flex;
  align-items: center;
  padding-inline: 1.4vw;
  transition: all 0.3s ease-in-out;
  font-size: 0.9vw;
  font-weight: 700;
`;
export const DivInputSearch = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  /* margin-block: 0.7vh; */
  margin-bottom: 0.7vh;
`;
export const SearchIcon = styled.img`
  position: absolute;
  z-index: 11;
  top: 0;
  marker-start: 0;
  padding-inline-start: 0.4vw;
  height: 100%;
`;
// -- start Header -- //

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 3vh;
`;
export const Head = styled.div`
  display: flex;
  align-items: center;
`;
export const DivIconHead = styled.div`
  height: 3.8vw;
  width: 3.8vw;
  margin-inline-end: 0.6vw;
  border-radius: 0.7vw;
  background: var(--orange);
`;
export const IconHead = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 0.7vw;
  ${({ isLogo }) => (isLogo ? "" : "filter: brightness(0) invert(1);")}
`;
export const DivTitleHead = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.4vh;
  transition: all 0.2s ease-in-out;
`;
export const TitleHead = styled.p`
  color: black;
  font-size: 1vw;
  font-weight: 800;
`;
export const StatusHead = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4vw;
  color: black;
  font-size: 0.7vw;
  font-weight: 600;
`;
export const DivColorStatus = styled.div`
  background: ${({ color }) => color};
  width: 0.7vw;
  height: 0.7vw;
  border-radius: 50%;
`;
export const DivEndHead = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.94vw;
  font-weight: 800;
  column-gap: 0.6vw;
  color: ${({ status }) =>
    (status === "PENDING" && "var(--lightBrown)") ||
    (status === "AGREE" && "var(--green)") ||
    (status === "DENY" && "var(--red)") ||
    (status === "INVALID" && "var(--lable)")};
`;

export const BtnStatus = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9vw;
  font-weight: 700;
  padding-block: 0.6vw;
  padding-inline: 1vw;
  border-radius: 0.7vw;
  column-gap: 0.6vw;
  cursor: pointer;
  background: var(--red);
  color: white;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: var(--darkRed);
  }
`;
export const DivTime = styled.div`
  display: flex;
  align-items: center;
  font-size: 1vw;
  font-weight: 800;
  padding: 0.6vw;
  border-radius: 0.7vw;
  column-gap: 0.6vw;
  background: ${({ status }) =>
    (status === "PENDING" && "var(--backgroundDetailCompanyRequestTime)") ||
    (status === "AGREE" &&
      "var(--backgroundBoxRoysaAcceptResponseToCompanies)") ||
    (status === "DENY" &&
      "var(--backgroundBoxRoysaRejectResponseToCompanies)") ||
    (status === "INVALID" && "var(--backgroundBoxDetail)")};
`;
export const CalendarTime = styled.p`
  color: black;
  font-weight: 800;
`;
export const SeparatorTime = styled.div`
  width: 0.18vw;
  background: var(--lightBrown);
  height: 2.8vh;
  border-radius: 0.5vw;
  background: ${({ status }) =>
    (status === "PENDING" && "var(--lightBrown)") ||
    (status === "AGREE" && "var(--green)") ||
    (status === "DENY" && "var(--red)") ||
    (status === "INVALID" && "var(--lable)")};
`;
export const ClockTime = styled.p`
  color: black;
  font-weight: 800;
`;
// -- end Header -- //
// -- start Body -- //
export const Body = styled.div`
  height: 57vh;
  width: 100%;
  padding-top: 2vh;
  display: flex;
  column-gap: 4.6vw;
  row-gap: 3vh;
  flex-direction: column;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const InputSearch = styled.input`
  padding-inline-start: 3.4vw;
  padding-inline-end: 1.2vw;
  font-size: 0.9vw;
  height: 6vh;
  width: 100%;
  background-color: ${({ disabled }) =>
    disabled ? "#f4f7f7" : "var(--inputBackground)"};
  color: ${({ disabled }) => (disabled ? "#B5B5B5" : "")};
  ${({ disabled }) => disabled && "cursor: not-allowed;"}
  border-radius: 1.6vh;
  border: 0.11vw solid var(--inputStroke);
  margin: 0;
`;
export const DivRow = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 2vw;
`;
export const DivColumn = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3vh;
`;
export const DivDetail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const Lable = styled.label`
  color: var(--lable);
  font-size: 0.9vw;
  font-weight: 500;
  white-space: nowrap;
  ${({ disablePadding }) =>
    disablePadding
      ? "padding-bottom: 0;color:white;opacity:0.8;"
      : "padding-bottom: 1vh;"}
  padding-inline-start: 0.5vw;
`;
export const DivDetailProduct = styled.div`
  width: 100%;
  display: flex;
  ${({ sum }) =>
    sum
      ? "color:white;background:var(--brown);"
      : "background: var(--backgroundBoxDetail);"}

  padding: 1.6vh 1vw;
  border-radius: 0.7vw;
`;

export const CoverImgProductTable = styled.div`
  height: 2.3vw;
  min-width: 2.3vw;
  max-width: 2.3vw;
  background: white;
  border-radius: 50%;
  border: 0.1vw dashed var(--dashedDetail);
  margin: auto;
`;
export const ImgProductTable = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%;
`;
export const SeparatorDetailProduct = styled.div`
  height: 100%;
  min-width: 0.1vw;
  max-width: 0.1vw;
  border-left: 0.1vw dashed var(--dashedDetail);
`;
export const DivDetailText = styled.div`
  width: 100%;
  display: flex;
`;
export const DetailText = styled.div`
  min-width: 40%;
  display: flex;
`;
export const DivText = styled.div`
  width: 100%;
  display: flex;
  ${({ row }) =>
    row
      ? "flex-direction:row;justify-content: space-between;padding-inline-end: 1.1vw;"
      : "flex-direction:column;"}
  ${({ disablePadding }) =>
    disablePadding ? "padding-top: 0;" : "padding-top: 0.5vh;"}
`;
export const Text = styled.div`
  display: flex;
  padding-inline-start: 0.6vw;
  flex-direction: row;
  column-gap: 0.5vw;
  font-size: 0.9vw;
  font-weight: 800;
`;

export const DivDetailRequest = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.6vw;
  background: var(--backgroundBoxDetail);
  padding: 1.6vh 1vw;
  border-radius: 0.7vw;
`;
export const DivImages = styled.div`
  padding: 0.6vw;
  font-size: 0.9vw;
  width: 100%;
  border-radius: 0.8vw;
  border: 0.16vh dashed var(--dashedStroke);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.6vw;
  flex-direction: row;
  overflow-y: hidden;
  &:-ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const CoverImage = styled.div`
  height: 10vw;
  min-width: 10vw;
  max-width: 10vw;
  border-radius: 0.5vw;
`;
export const DivDescription = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.5vw;
  font-size: 0.9vw;
  font-weight: 800;
`;
export const LableDetailRequestDescription = styled.label`
  color: var(--lable);
  font-size: 0.9vw;
  font-weight: 500;
  display: inline-block;
  white-space: nowrap;
`;
export const LableDetailRequest = styled.label`
  color: var(--lable);
  font-size: 0.9vw;
  font-weight: 500;
  display: flex;
  align-items: center;
`;
export const DivPrice = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  column-gap: 0.5vw;
  font-size: 0.9vw;
  font-weight: 800;
`;
export const DivWeight = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  column-gap: 0.5vw;
  font-size: 0.9vw;
  font-weight: 800;
`;
export const BeforePrice = styled.p`
  font-size: 1vw;
  font-weight: 900;
  color: ${({ before }) => (before ? "var(--disabledColor)" : "var(--green)")};
  display: flex;
  align-items: center;
`;
export const NowPrice = styled.p`
  font-size: 1vw;
  font-weight: 900;
  color: var(--green);
  background: var(--backgroundPrice);
  padding: 0.2vw 0.5vw;
  border-radius: 0.4vw;
  display: flex;
  align-items: center;
`;
export const BeforeStock = styled.p`
  font-size: 1vw;
  font-weight: 900;
  color: var(--orange);
  display: flex;
  align-items: center;
`;
export const NowStock = styled.p`
  font-size: 1vw;
  font-weight: 900;
  color: var(--orange);
  background: var(--backgroundStoke);
  padding: 0.2vw 0.5vw;
  border-radius: 0.4vw;
  display: flex;
  align-items: center;
`;
export const Unit = styled.span`
  font-size: 0.8vw;
  font-weight: 700;
  padding-inline-start: 0.4vw;
  color: ${({ before }) => (before ? "var(--disabledColor)" : "black")};
`;
export const CoverArrow = styled.div`
  height: 100%;
  margin-inline: 1vw;
`;
export const Table = styled.table`
  width: 100%;
  text-align: center;
  border-collapse: separate;
  border-spacing: 0 0.5vw;
`;
export const Tr = styled.tr`
  background: white;
  border-radius: 10px;
  font-size: 0.9vw;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
  margin-bottom: 10vw;
  &:hover {
    background: var(--hoverTr);
  }
`;
export const Thead = styled.tr`
  color: var(--lable);
  font-size: 0.9vw;
`;
export const Td = styled.td`
  &:first-child {
    border-radius: 0 10px 10px 0;
    padding: 0;
  }
  &:last-child {
    border-radius: 10px 0 0 10px;
  }
  padding-block: 0.8vw;
`;
export const Th = styled.td`
  padding-bottom: 0.5vw;
`;
// -- end Body -- //
