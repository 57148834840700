import styled from "styled-components";
export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5.6vh 3.3vw;
`;

// -- start Header -- //

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 3vh;
`;
export const DivIconHead = styled.div`
  height: 3.2vw;
  width: 3.2vw;
  background: ${({ deleteModal }) =>
    deleteModal ? "var(--red)" : "var(--orange)"};
  margin-inline-end: 0.6vw;
  border-radius: 50%;
`;
export const IconHead = styled.img`
  height: 100%;
  filter: brightness(0) invert(1);
`;

export const TitleHead = styled.p`
  color: black;
  font-size: 0.94vw;
  font-weight: 700;
`;

// -- end Header -- //

// -- start Body -- //

export const Body = styled.div`
  padding-top: 5vh;
  width: 100%;
  display: flex;
  column-gap: 4.6vw;
  row-gap: 3vh;
  flex-direction: column;
`;
export const DivText = styled.div`
  width: 19.4vw;
  text-align: justify;
  font-size: 0.94vw;
  font-weight: 600;
`;
export const DeleteElement = styled.span`
  color: var(--red);
  font-weight: 700;
`;
export const CoverImg = styled.div`
  height: 8.4vw;
  width: 100%;
  border: solid 0.1vw var(--inputStroke);
  background: var(--inputBackground);
  border-radius: 1.3vw;
  padding: 0.3vw;
  margin: auto;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
`;
export const Img = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 0.9vw;
`;
export const DivInput = styled.div`
  width: 19.4vw;
  display: flex;
  flex-direction: column;
`;
export const DivToggleButton = styled.div`
  padding: 0.16vw;
  height: 6vh;
  width: 100%;
  background-color: var(--inputBackground);
  border: 0.11vw solid var(--inputStroke);
  border-radius: 1.6vh;
  display: flex;
  cursor: pointer;
`;
export const ToggleButton = styled.button`
  height: 100%;
  width: 34%;
  font-size: 0.9vw;
  font-weight: 700;
  ${({ active }) =>
    active
      ? "background: var(--brown);color: white;"
      : "background: none;color: var(--lable);cursor: pointer; &:hover {background: var(--hoverToggleButton);}"}
  border-radius: 1.2vh;
  border: none;
  transition: all 0.2s ease-in-out;
`;

export const Input = styled.input`
  padding: 0 1.2vw 0 1.2vw;
  font-size: 0.9vw;
  height: 6vh;
  width: 100%;
  background-color: ${({ disabled }) =>
    disabled ? "#f4f7f7" : "var(--inputBackground)"};
  color: ${({ disabled }) => (disabled ? "#B5B5B5" : "")};
  ${({ disabled }) => disabled && "cursor: not-allowed;"}
  border-radius: 1.6vh;
  border: 0.11vw solid var(--inputStroke);
  margin: 0;
`;
export const Textarea = styled.textarea`
  padding: 1.8vh 1.2vw;
  font-size: 0.9vw;
  height: 13vh;
  width: 100%;
  background-color: var(--inputBackground);
  resize: none;
  border-radius: 1.6vh;
  border: 0.11vw solid var(--inputStroke);
  margin: 0;
  &:focus {
    background: rgba(255, 255, 255, 0.4);
    color: black;
    outline: none;
  }
  &::-webkit-input-placeholder {
    color: var(--placeholder);
  }
  &:-ms-input-placeholder {
    color: var(--placeholder);
  }
  &::placeholder {
    color: var(--placeholder);
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance:   
    transition: background-color 600000s 0s, color 600000s 0s;
  }
`;
export const Lable = styled.label`
  color: var(--lable);
  font-size: 0.9vw;
  font-weight: 500;
  padding-bottom: 1vh;
  padding-inline-start: 0.5vw;
`;
export const ActivRoleCheckbox = styled.div`
  height: 1.1vw;
  width: 1.1vw;
  border-radius: 0.5vh;
  border: 0.1vw solid
    ${({ active }) => (active ? "var(--brown)" : "var(--checkboxStroke)")};
  margin-inline-end: 0.6vw;
  background-color: ${({ active }) => (active ? "var(--brown)" : "white")};
  transition: all 0.2s ease-in-out;
  cursor: pointer;
`;
export const DashedSubPermissions = styled.div`
  height: 100%;
  border: dashed 0.05vw var(--dashedStroke);
`;
export const DivBoxSubPermissions = styled.div`
  padding-inline-start: 1.02vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.8vw;
`;
export const DivPermissions = styled.div`
  font-size: 0.9vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.3vw;
  flex-direction: column;
`;
export const DivPermission = styled.div`
  padding: 1vh;
  font-size: 0.9vw;
  height: 4.7vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.3vw;
  color: var(--brown);
  font-weight: 700;
`;
export const DashedSubCategories = styled.div`
  height: 100%;
  border: dashed 0.05vw var(--dashedStroke);
`;

export const DivSelect = styled.div`
  padding: 1vh;
  font-size: 0.9vw;
  height: 47.7vh;
  width: 100%;
  border-radius: 1.6vh;
  border: 0.16vh dashed var(--dashedStroke);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.3vw;
  flex-direction: column;
  overflow-x: hidden;
  scrollbar-width: none; /* Firefox */
  &:-ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const DivSubPermission = styled.div`
  font-size: 0.9vw;
  height: 4vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.3vw;
`;
export const DivSubPermissions = styled.div`
  font-size: 0.9vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.3vw;
  flex-direction: column;
  font-weight: 500;
  color: var(--lightBrown);
`;
export const DivBoxSubCategories = styled.div`
  padding-inline-start: 1.6vw;
  padding-block: 0.7vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.8vw;
`;
export const DivCategories = styled.div`
  font-size: 0.9vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.3vw;
  flex-direction: column;
`;
export const DivCategory = styled.div`
  padding: 1vh;
  font-size: 0.9vw;
  height: 4.7vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.3vw;
  color: var(--brown);
  font-weight: 700;
`;

export const DivSubCategory = styled.div`
  font-size: 0.9vw;
  height: 4vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.3vw;
`;
export const DivSubCategories = styled.div`
  font-size: 0.9vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.3vw;
  flex-direction: column;
  font-weight: 500;
  color: var(--lightBrown);
`;

// -- end Body -- //

// -- start Button -- //

export const Btn = styled.button`
  width: ${({ large }) => (large ? "100%" : "46%;")};
  font-size: 0.9vw;
  border-radius: 1.6vh;
  align-items: center;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  background: ${({ okBtn }) => (okBtn ? "var(--orange)" : "var(--red)")};
  font-weight: 600;
  color: white;
  text-decoration: none;
  padding: 1.5vh 0.3vw;
  transition: all 0.2s ease-in-out;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: ${({ okBtn }) =>
      okBtn ? "var(--darkOrange)" : "var(--darkRed)"};
    color: white;
  }
`;
export const BtnDiv = styled.div`
  ${({ btnPadding }) => btnPadding && "padding-top:1.8vh"};
  width: 19.4vw;
  display: flex;
  flex-direction: ${({ rowReverse }) => (rowReverse ? "row-reverse" : "row")};
  align-items: flex-end;
  justify-content: space-between;
`;
export const BtnIcon = styled.img`
  width: 1.2vw;
  margin-left: 0.1vw;
  cursor: pointer;
  border-radius: 0.5vh;
  ${({ hover }) => hover && "&:hover {background-color: #3a7599;}"}
`;

// -- end Button -- //
