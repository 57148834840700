import { useEffect, useState } from "react";
import DeleteModal from "react-modal";
import axiosConfig from "../../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";

import {
  Body,
  Container,
  Header,
  TitleHead,
  Btn,
  BtnDiv,
  DivIconHead,
  IconHead,
  DivText,
  DeleteElement,
} from "./Element";
import deleteIcon from "../../../Images/headerIcons/delete.svg";
import checkIcon from "../../../Images/headerIcons/check.svg";
import { useTranslation } from "react-i18next";

const Delete = ({
  showModalDelete,
  handleDelete,
  handleCloseModal,
  detailItem,
}) => {
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [checkModal, setCheckModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (showModalDelete) {
      async function fetchData() {
        const toastId = toast.loading(t("receiving information ..."));
        const token = localStorage.getItem("tokenDeliveryCompanyRoysa");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        };

        try {
          const { data } = await axiosConfig.get(
            "/company/role/check/" + detailItem?.id,
            config
          );
          const itemsData = data.data;
          console.log(itemsData.have_data);
          setCheckModal(itemsData.have_data);
          toast.dismiss(toastId);
        } catch (error) {
          toast.update(toastId, {
            render: t("unable to connect to server"),
            type: "error",
            isLoading: false,
            autoClose: 3000,
            closeOnClick: true,
          });
          console.log("Error fetching roles:", error);
        }
        setShowModal(showModalDelete);
      }
      fetchData();

      // console.log(detailItem);
    } else {
      setShowModal(showModalDelete);
    }
  }, [showModalDelete, detailItem]);

  const CloseModal = () => {
    handleCloseModal("modalDelete");
    setErrors({});
  };
  const handleSubmit = async () => {
    if (!disabledBtn) {
      setDisabledBtn(true);
      let isOkResponse;
      const toastId = toast.loading(t("deleting role ..."));
      const token = localStorage.getItem("tokenDeliveryCompanyRoysa");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      };
      try {
        await axiosConfig.delete(
          "/company/role/delete/" + detailItem?.id,
          config
        );
        isOkResponse = true;
        setDisabledBtn(false);
      } catch (error) {
        isOkResponse = false;
        console.log("Error response from server:", error.response.data.status);
        toast.update(toastId, {
          render: t("unable to connect to server"),
          type: "error",
          isLoading: false,
          autoClose: 3000,
          closeOnClick: true,
        });
        setDisabledBtn(false);
      }
      if (isOkResponse) {
        handleDelete(toastId);
        CloseModal();
      }
    }
  };

  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <DeleteModal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={CloseModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <Container>
          <Header>
            <DivIconHead deleteModal>
              <IconHead src={checkModal ? checkIcon : deleteIcon} />
            </DivIconHead>
            <TitleHead>
              {checkModal
                ? t("no permission to remove role")
                : t("delete role")}
            </TitleHead>
          </Header>
          <Body>
            <DivText>
              {checkModal ? (
                <>
                  از نقش{" "}
                  <DeleteElement>{detailItem?.persian_title}</DeleteElement> در
                  نرم افزار استفاده می شود به همین دلیل شما مجاز به حذف آن
                  نیستید.
                </>
              ) : (
                <>
                  آیا از حذف نقش{" "}
                  <DeleteElement>{detailItem?.persian_title}</DeleteElement>{" "}
                  اطمینان دارید؟
                </>
              )}
            </DivText>
            {checkModal ? (
              <BtnDiv btnPadding={true}>
                <Btn large onClick={CloseModal}>
                  {t("I realized")}
                </Btn>
              </BtnDiv>
            ) : (
              <BtnDiv btnPadding={true}>
                <Btn okBtn onClick={handleSubmit}>
                  {t("delete")}
                </Btn>
                <Btn onClick={CloseModal}>{t("cancel")}</Btn>
              </BtnDiv>
            )}
          </Body>
        </Container>
      </DeleteModal>
    </>
  );
};

export default Delete;
