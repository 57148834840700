import styled from "styled-components";
import { Link } from "react-router-dom";
export const Container = styled.nav`
  border-radius: 1.6vh;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  width: 100%;
  flex-direction: row;
  grid-area: col1;
`;
export const Tabs = styled.ul`
  overflow: auto;
  display: flex;
  text-align: center;
  color: white;
  align-items: center;
  list-style: none;
  flex-direction: row;
  padding: 0;
  margin-bottom: 0;
  height: 100%;
  width: 93%;
  padding-right: 2vw;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
`;
export const Profile = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  min-width: 7%;
`;
export const DivProfile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
export const ProfileCover = styled.img`
  width: 100%;
`;
export const ProfileImg = styled.img`
  ${({ defaultImg }) =>
    defaultImg
      ? "filter: brightness(0) invert(1);width: 60%;height: 60%;"
      : "width: 50%;height: 50%;"};
  border-radius: 50%;
  position: absolute;
`;

export const Tab = styled.li`
  display: flex;
  text-align: center;
  align-content: center;
  margin-inline-start: 1.5vw;
  max-height: 4vh;
  ${({ active }) => (active ? "background: var(--activeTabBackground);" : "")};
  border-radius: 15vw;
  cursor: default;

  &:hover {
    ${({ active }) =>
      !active ? " background: var(--hoverTabBackground);" : ""};
  }
`;
export const TabLink = styled(Link)`
  height: 100%;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.6vh 1vw;
  border-radius: 15vw;
  transition: 0.3s ease-in-out;
  ${({ active }) => (active ? "cursor: default;" : "cursor: pointer;")};
  &.active {
    border-bottom: solid 3px #1d9bb4;
    color: #1d9bb4;
  }
`;
export const TabTitle = styled.p`
  color: var(--brown);
  font-size: 0.9vw;
  font-weight: 600;
`;
export const DetailUser = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;
export const Name = styled.p`
  color: var(--brown);
  font-size: 0.94vw;
  font-weight: 700;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const Role = styled.p`
  color: var(--brown);
  font-size: 0.8vw;
  font-weight: 500;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
