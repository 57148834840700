import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

import {
  Container,
  Item,
  Card,
  Btn,
  Icon,
  DetailItem,
  Title,
  Price,
  BtnAdd,
  IconBtnAdd,
  CoverImg,
  Img,
} from "./Element";
import card from "../../Images/cards/user.svg";
import card_ltr from "../../Images/cards/role_ltr.svg";
import setting from "../../Images/setting.svg";
import icon from "../../Images/addIcon.svg";
import defaultAvatar from "../../Images/defaultAvatar.svg";
import { useEffect, useState } from "react";
const List = ({ data, handleOpenModal }) => {
  const [items, setItems] = useState([]);
  const currentLanguageCode = cookies.get("i18next");
  const { t } = useTranslation();
  useEffect(() => {
    if (data) {
      setItems(data);
    }
  }, [data]);

  return (
    <>
      <Container>
        {items.map((item) => (
          <Item>
            <Card src={currentLanguageCode === "fa" ? card : card_ltr} />
            <CoverImg>
              <Img src={item?.avatar ? item?.avatar : defaultAvatar} />
            </CoverImg>
            <DetailItem>
              <Title>{item?.name + " " + item?.family}</Title>
              <Price>{item?.company_role?.persian_title}</Price>
            </DetailItem>
            <Btn onClick={() => handleOpenModal("modalOperations", null, item)}>
              <Icon src={setting} />
            </Btn>
          </Item>
        ))}
        <BtnAdd onClick={() => handleOpenModal("addModal")}>
          <IconBtnAdd src={icon} />
          {t("add user")}
        </BtnAdd>
      </Container>
    </>
  );
};

export default List;
