import { useEffect, useState } from "react";
import Modal from "react-modal";

import {
  Body,
  Container,
  Header,
  TitleHead,
  DivItems,
  Btn,
  BtnDiv,
  DivIconHead,
  IconHead,
  ListItems,
  DivHeader,
  Lable,
  TitleBtn,
  Detail,
  Admin,
  DivAdmin,
  IconDetail,
  DivList,
  IconOperationHead,
  DivDetailText,
  DetailText,
  SeparatorDetailProduct,
  DivText,
  Text,
} from "./OperationsElement";
import icon from "../../../Images/defaultAvatar.svg";
import editIcon from "../../../Images/editIcon.svg";
import deleteIcon from "../../../Images/deleteIcon.svg";

import { useTranslation } from "react-i18next";
import { DivColumn, DivRow } from "./Element";
// import AddRoleForm from "../../Form/AddRoleForm";

const Operations = ({
  showModalOperations,
  handleAddNew,
  handleCloseModal,
  handleOpenModal,
  detailItem,
  handleResetUpdateModal,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [item, setItem] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    console.log(detailItem);
    setShowModal(showModalOperations);
    setItem(detailItem);
  }, [detailItem, showModalOperations]);
  const CloseModal = () => {
    handleCloseModal("modalOperations");
  };
  const handleUpdate = () => {
    handleCloseModal("modalOperations");
    handleOpenModal("modalUpdate", null, detailItem);
  };
  const handleDelete = () => {
    handleCloseModal("modalOperations");
    handleOpenModal("modalDelete");
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={CloseModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <Container>
          <Header>
            <DivHeader>
              <DivIconHead>
                <IconHead
                  haveLogo={item?.avatar ? true : false}
                  src={item?.avatar ? item?.avatar : icon}
                />
              </DivIconHead>
              <TitleHead>{item?.name + " " + item?.family}</TitleHead>
            </DivHeader>
            <BtnDiv btnPadding={true}>
              <Btn operation="EDIT" onClick={handleUpdate}>
                <IconOperationHead src={editIcon} />
                <TitleBtn>{t("edit")}</TitleBtn>
              </Btn>
              <Btn operation="DELETE" onClick={handleDelete}>
                <IconOperationHead src={deleteIcon} />
                <TitleBtn>{t("delete")}</TitleBtn>
              </Btn>
            </BtnDiv>
          </Header>
          <Body>
            <DivItems>
              <Lable>{t("user information")}</Lable>
              <ListItems>
                <DivList>
                  <DivDetailText>
                    <DetailText>
                      <DivText>
                        <Text>
                          <Lable>{t("نقش")} :</Lable>
                          {detailItem?.company_role?.persian_title}
                        </Text>
                        <Text>
                          <Lable>{t(" شرکت پخش")} :</Lable>
                          {detailItem?.company_title}
                        </Text>
                      </DivText>
                    </DetailText>
                    <DetailText>
                      <SeparatorDetailProduct />
                      <DivText>
                        <Text>
                          <Lable>{t("نام کاربری")} :</Lable>
                          {detailItem?.username}
                        </Text>
                        <Text>
                          <Lable>{t("شماره موبایل")} :</Lable>
                          {detailItem?.mobile}
                        </Text>
                      </DivText>
                    </DetailText>
                  </DivDetailText>
                  <DivText>
                    <Text>
                      <Lable>{t("ایمیل")} :</Lable>
                      {detailItem?.email}
                    </Text>
                  </DivText>
                </DivList>
              </ListItems>
            </DivItems>
          </Body>
        </Container>
      </Modal>
    </>
  );
};

export default Operations;
