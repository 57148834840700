import React from "react";
import { MainContainer, Wrapper } from "./MainElements";
import { Outlet } from "react-router";
const SoftwareMain = () => {
  return (
    <MainContainer>
      <Wrapper>
        <Outlet/>

      </Wrapper>
    </MainContainer>
  );
};

export default SoftwareMain;
