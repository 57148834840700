import styled from "styled-components";
export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5.6vh 3.3vw;
`;

// -- start Header -- //

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3vh;
`;
export const HeaderDiscount = styled.div`
  display: flex;
  align-items: center;
  height: 3vh;
`;
export const DivHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const DivIconHead = styled.div`
  height: 3.2vw;
  width: 3.2vw;
  background: var(--orange);
  margin-inline-end: 0.6vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const DivTitleHead = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
export const IconHead = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  ${({ haveLogo }) => (haveLogo ? "" : "filter: brightness(0) invert(1);")}
`;

export const TitleHead = styled.p`
  color: black;
  font-size: 0.94vw;
  font-weight: 700;
`;
export const TitleCategoryHead = styled.p`
  color: var(--lightBrown);
  font-size: 0.7vw;
  font-weight: 600;
`;

// -- end Header -- //

// -- start Body -- //

export const Body = styled.div`
  padding-top: 5vh;
  width: 100%;
  display: flex;
  column-gap: 4.6vw;
  row-gap: 3vh;
  flex-direction: column;
`;

export const IconDetail = styled.img`
  height: 100%;
`;

export const DivItems = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Lable = styled.label`
  color: var(--lable);
  font-size: 0.9vw;
  font-weight: 500;
  padding-bottom: 1vh;
  padding-inline-start: 0.5vw;
`;
export const DivList = styled.div`
  height: 100%;
  width: 60vw;
  border-radius: 0.8vw;
  display: flex;
  flex-wrap: nowrap;
gap: 0.8vw;
  overflow-y: hidden;
  &:-ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const ListItems = styled.div`
  position: relative;
  padding: 1vw;
  width: 100%;
  border-radius: 1.6vh;
  border: 0.16vh dashed var(--dashedStroke);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-direction: column;
`;

export const CoverImgUser = styled.div`
  height: 6vh;
  width: 6vh;
  border: solid 0.1vw var(--inputStroke);
  background: var(--selectBackground);
  transition: all 0.2s ease-in-out;
  border-radius: 1.6vh;
`;

export const Helper = styled.p`
  font-size: 0.8vw;
  font-weight: 600;
  padding-inline-start: 0.4vw;
  color: black;
  padding-top: 0.2vw;
`;

export const Price = styled.span`
  font-weight: 900;
  color: var(--red);
`;
export const DivRow = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 2vw;
`;
export const DivInputDescription = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const CoverImg = styled.div`
  position: relative;
  height: 9vw;
  width: 9vw;
  border-radius: 0.8vw;
  background: var(--inputBackground);
  border: solid 0.1vw var(--inputStroke);
  ${({ isImage }) =>
    isImage
      ? ""
      : "cursor: pointer;&:hover {border: solid 0.1vw var(--green);}"}
`;
export const Img = styled.img`
  height: 100%;
  border-radius: 0.7vw;
  ${({ isImage }) =>
    isImage ? "min-width:8.8vw;max-width:8.8vw;" : "min-width: 100%;"}
`;
export const DivDelete = styled.div`
  position: absolute;
  top: 0;
  height: 2.4vw;
  width: 2.4vw;
  border-radius: 0 0.6vw;
  background: var(--backgroundDeleteImage);
  cursor: pointer;
  &:hover {
    background: var(--red);
  }
`;

// -- end Body -- //
// -- start Button -- //

export const Btn = styled.button`
  width: 46%;
  font-size: 0.9vw;
  border-radius: 1.6vh;
  align-items: center;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  background: ${({ okBtn }) => (okBtn ? "var(--orange)" : "var(--red)")};
  font-weight: 600;
  color: white;
  text-decoration: none;
  padding: 1.5vh 0.3vw;
  transition: all 0.2s ease-in-out;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: ${({ okBtn }) =>
      okBtn ? "var(--darkOrange)" : "var(--darkRed)"};
    color: white;
  }
`;
export const BtnDiv = styled.div`
  ${({ btnPadding }) => btnPadding && "padding-top:1.8vh"};
  width: 19.4vw;
  display: flex;
  flex-direction: ${({ rowReverse }) => (rowReverse ? "row-reverse" : "row")};
  align-items: flex-end;
  justify-content: space-between;
`;
export const BtnIcon = styled.img`
  width: 1.2vw;
  margin-left: 0.1vw;
  cursor: pointer;
  border-radius: 0.5vh;
  ${({ hover }) => hover && "&:hover {background-color: #3a7599;}"}
`;

// -- end Button -- //
