import {
  Container,
  Item,
  Img,
  CoverImg,
  Title,
  Description,
  Div,
  Time,
  Detail,
  DivFilters,
  Btnfilter,
  DivInputSearch,
  SearchIcon,
  StatusDiv,
  DivItems,
  InputSearch,
  TextNoRequest,
} from "./ElementTradeUnionsRequests";
import defaultLogo from "../../Images/headerIcons/tradeUnions.svg";
import searchIcon from "../../Images/search.svg";
import agreeIcon from "../../Images/agreeIcon.svg";
import denyIcon from "../../Images/denyIcon.svg";
import invalidIcon from "../../Images/invalidIcon.svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
const List = ({ data, filter, filterStatus, handleSelectItem, filters }) => {
  const [items, setItems] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [activeCompany, setActiveCompany] = useState(null);

  const { t } = useTranslation();
  useEffect(() => {
    if (data) {
      setItems(data);
    }
  }, [data]);
  const handleSelectTab = (status) => {
    filterStatus(status);
    setActiveTab(status);
  };
  const handleSelectCompany = (item) => {
    handleSelectItem(item);
    setActiveCompany(item.id);
  };
  const handleDate = (date) => {
    date = date.split(" ");
    let time = date[3].split(":");
    if (date[1] === "فروردین") {
      date[1] = 1;
    } else if (date[1] === "اردیبهشت") {
      date[1] = 2;
    } else if (date[1] === "خرداد") {
      date[1] = 3;
    } else if (date[1] === "تیر") {
      date[1] = 4;
    } else if (date[1] === "مرداد") {
      date[1] = 5;
    } else if (date[1] === "شهریور") {
      date[1] = 6;
    } else if (date[1] === "مهر") {
      date[1] = 7;
    } else if (date[1] === "آبان") {
      date[1] = 8;
    } else if (date[1] === "آذر") {
      date[1] = 9;
    } else if (date[1] === "دی") {
      date[1] = 10;
    } else if (date[1] === "بهمن") {
      date[1] = 11;
    } else if (date[1] === "اسفد") {
      date[1] = 12;
    }
    return (
      time[0] + ":" + time[1] + " | " + date[2] + "/" + date[1] + "/" + date[0]
    );
  };
  return (
    <>
      <Container>
        <DivFilters>
          <Btnfilter
            onClick={() => handleSelectTab(0)}
            active={activeTab === 0}
          >
            همه
          </Btnfilter>
          {filters.map((item) => (
            <Btnfilter
              onClick={() => handleSelectTab(item?.id)}
              active={item?.id === activeTab}
            >
              {item?.title}
            </Btnfilter>
          ))}
        </DivFilters>
        <DivInputSearch>
          <SearchIcon src={searchIcon} />
          <InputSearch
            placeholder="جستجو کنید ..."
            id="search"
            onChange={filter}
          />
        </DivInputSearch>
        {items.length !== 0 ? (
          <DivItems>
            {items.map((item) => (
              <Item
                active={item.id === activeCompany}
                onClick={() => handleSelectCompany(item)}
              >
                <CoverImg>
                  <Img
                    isLogo={item?.branch?.trade_unit?.logo}
                    src={
                      item?.branch?.trade_unit?.logo
                        ? item?.branch?.trade_unit?.logo
                        : defaultLogo
                    }
                  />
                </CoverImg>
                <Detail>
                  <Div>
                    <Title active={item.id === activeCompany}>
                      {item?.branch?.trade_unit?.title +
                        " _ " +
                        item?.branch?.title}
                    </Title>
                    <Time
                      status={
                        // item?.status === 0 &&
                        (item.id !== activeCompany && "PENDING") ||
                        (item?.status === 0 &&
                          item.id === activeCompany &&
                          "ACTIVE_PENDING") ||
                        (item?.status === 1 && "AGREE") ||
                        (item?.status === 2 && "DENY") ||
                        (item?.status === 3 && "INVALID")
                      }
                    >
                      {handleDate(item?.jalali_payment_date)}
                    </Time>
                  </Div>
                  <Description active={item.id === activeCompany}>
                    {"شماره فاکتور : " + item?.number}
                  </Description>
                </Detail>
              </Item>
            ))}
          </DivItems>
        ) : (
          <DivItems>
            <TextNoRequest>
              هنوز برای شرکت شما فاکتوری ثبت نشده است ...
            </TextNoRequest>
          </DivItems>
        )}
      </Container>
    </>
  );
};

export default List;
