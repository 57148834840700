import Joi from "joi";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import axiosConfig from "../../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

import {
  Body,
  Container,
  Header,
  TitleHead,
  Input,
  DivInput,
  Lable,
  Btn,
  BtnDiv,
  DivIconHead,
  IconHead,
  Textarea,
  CoverImg,
  Img,
} from "./Element";
import icon from "../../../Images/headerIcons/sliders.svg";
import iconSelect from "../../../Images/selectImg/slider.svg";

const Add = ({ lastId, showAddModal, handleAddNew, handleCloseModal }) => {
  const [data, setData] = useState({
    image: "",
    link: "",
    position: "",
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const currentLanguageCode = cookies.get("i18next");
  const { t } = useTranslation();

  useEffect(() => {
    setShowModal(showAddModal);
    setData({
      image: "",
      link: "",
      position: "",
    });
    setErrors({});
  }, [lastId, showAddModal]);

  const schema = Joi.object({
    image: Joi.string().required(),
    link: Joi.string().required(),
    position: Joi.string().required(),
  });
  const CloseModal = () => {
    handleCloseModal("addModal");
  };
  const handleChange = (e) => {
    setData({ ...data, [e.currentTarget.id]: e.currentTarget.value });
  };
  const handleNumber = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  const selectFile = () => {
    document.getElementById("fname").click();
  };
  const onSelectImage = (e) => {
    if (e.currentTarget && e.currentTarget.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Image = e.target.result;
        setData({ ...data, image: base64Image });
      };
      reader.readAsDataURL(e.currentTarget.files[0]);
      console.log(data);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validate();
    setErrors({ errors: errors || {} });
    if (errors) return;
    doSubmit();
  };
  const validate = () => {
    const result = schema.validate(data, { abortEarly: false });
    let errorMessage = "";
    if (!result.error) return null;
    if (result.error.details[0].path[0] === "image") {
      errorMessage = "تصویر اسلایدر نمی تواند خالی باشد";
    } else if (result.error.details[0].path[0] === "position") {
      errorMessage = "موقعیت اسلایدر نمی تواند خالی باشد";
    } else if (result.error.details[0].path[0] === "link") {
      errorMessage = "لینک اسلایدر نمی تواند خالی باشد";
    }
    toast.warning(errorMessage);
    console.log(result.error);
    const errors = {};
    for (const item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const doSubmit = async () => {
    let isOkResponse;
    const toastId = toast.loading("در حال ایجاد اسلایدر ...");
    const token = localStorage.getItem("tokenDeliveryCompanyRoysa");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      await axiosConfig.post("/slider/company/store", data, config);
      isOkResponse = true;
    } catch (error) {
      isOkResponse = false;
      console.log("Error response from server:", error.response.data.status);
      console.log(errors);
      toast.update(toastId, {
        render: "خطا در اتصال به سرور",
        type: "error",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
    }
    if (isOkResponse) {
      handleAddNew(toastId);
      CloseModal();
    }
  };

  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={CloseModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <Container>
          <Header>
            <DivIconHead>
              <IconHead src={icon} />
            </DivIconHead>
            <TitleHead>{t("create new slider")}</TitleHead>
          </Header>
          <Body>
            <DivInput>
              <CoverImg onClick={selectFile}>
                <Img src={data?.image ? data?.image : iconSelect} />
              </CoverImg>
              <input
                hidden
                type="file"
                id="fname"
                name="fname"
                onChange={onSelectImage}
              ></input>
            </DivInput>

            <DivInput>
              <Lable>موقعیت اسلایدر</Lable>
              <Input
                id="position"
                onChange={handleChange}
                value={data.position}
                onKeyPress={handleNumber}
              />
            </DivInput>
            <DivInput>
              <Lable>لینک اسلایدر</Lable>
              <Textarea id="link" onChange={handleChange} value={data.link} />
            </DivInput>
            <BtnDiv btnPadding={true}>
              <Btn okBtn onClick={handleSubmit}>
                ایجاد
              </Btn>
              <Btn onClick={CloseModal}>انصراف</Btn>
            </BtnDiv>
          </Body>
        </Container>
      </Modal>
    </>
  );
};

export default Add;
