import React, { useEffect, useState } from "react";
import Search from "../../Components/Products/Search";
import List from "../../Components/Products/List";
import {
  Container,
  ListWrapper,
  SearchWrapper,
} from "../../Components/ContainerSoftware/ContainerElements";
import axiosConfig from "../../ApiCall/axiosConfig";
import { toast } from "react-toastify";

import ModalOperations from "../../Components/Products/Modals/Operations";

const Products = () => {
  const [categoriesItem, setCategoriesItems] = useState([]);
  const [categoryId, setCategoryId] = useState(0);
  const [subCategoryId, setSubCategoryId] = useState(0);
  const [items, setItems] = useState([]);
  const [detailItem, setDetailItem] = useState([]);

  const [searchItems, setSearchItems] = useState([]);
  const [filterItems, setFilterItems] = useState([]);

  const [showModalOperations, setShowModalOperations] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenModal = (modal, detailItem) => {
    if (modal === "modalOperations") {
      setShowModalOperations(true);
      setDetailItem(detailItem);
    }
  };
  const handleCloseModal = (modal) => {
    if (modal === "modalOperations") {
      setShowModalOperations(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("tokenDeliveryCompanyRoysa");
    const companyId = localStorage.getItem("companyId");
    const params = new URLSearchParams([
      ["per_page", 1000000],
      ["page", 1],
    ]);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
      params,
    };
    let firstCategoryId;
    async function fetchCategoriesData() {
      try {
        const { data } = await axiosConfig.get("/company/categories", config);
        const itemsData = data.data;
        setCategoriesItems(itemsData);
        setCategoryId(itemsData?.[0]?.id);
        firstCategoryId = itemsData?.[0]?.id;
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
    }

    async function fetchData() {
      try {
        const { data } = await axiosConfig.get(
          "/company_product/list/" + companyId,
          config
        );
        const itemsData = data.data;
        setSearchItems(itemsData);
        let results = itemsData.filter((item) => {
          return item?.category?.parent?.id === firstCategoryId;
        });
        console.log(results);
        setItems(results);
        setFilterItems(results);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
    }
    fetchCategoriesData();
    fetchData();
  }, []);

  const deleteImage = (toastId, detailItemId) => {
    async function fetchData() {
      const token = localStorage.getItem("tokenDeliveryCompanyRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get(
          "/company_product/list/" +
            parseInt(localStorage.getItem("companyId")),
          config
        );
        const itemsData = data.data;
        itemsData.forEach((element) => {
          if (element.id === detailItemId) {
            setDetailItem(element);
          }
        });
        let results;
        if (subCategoryId === 0) {
          results = itemsData.filter((item) => {
            return item?.category?.parent?.id === categoryId;
          });
        } else {
          results = itemsData.filter((item) => {
            return item?.category?.id === subCategoryId;
          });
        }
        setItems(results);
        setFilterItems(results);
        setSearchItems(itemsData);
        toast.update(toastId, {
          render: "تصویر با موفقیت حذف شد",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          closeOnClick: true,
        });
      } catch (error) {
        console.log("Error fetching ctegories:", error);
      }
    }
    fetchData();
  };
  const addImage = (toastId, detailItemId) => {
    async function fetchData() {
      const token = localStorage.getItem("tokenDeliveryCompanyRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get(
          "/company_product/list/" +
            parseInt(localStorage.getItem("companyId")),
          config
        );
        const itemsData = data.data;
        itemsData.forEach((element) => {
          if (element.id === detailItemId) {
            setDetailItem(element);
          }
        });
        let results;
        if (subCategoryId === 0) {
          results = itemsData.filter((item) => {
            return item?.category?.parent?.id === categoryId;
          });
        } else {
          results = itemsData.filter((item) => {
            return item?.category?.id === subCategoryId;
          });
        }
        setItems(results);
        setFilterItems(results);
        setSearchItems(itemsData);
        toast.update(toastId, {
          render: "تصویر با موفقیت اضافه شد",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          closeOnClick: true,
        });
      } catch (error) {
        console.log("Error fetching ctegories:", error);
      }
    }
    fetchData();
  };

  const handleAddNew = (toastId) => {
    async function fetchData() {
      const token = localStorage.getItem("tokenDeliveryCompanyRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get(
          "/company_product/list/" +
            parseInt(localStorage.getItem("companyId")),
          config
        );
        const itemsData = data.data;
        let results;
        if (subCategoryId === 0) {
          results = itemsData.filter((item) => {
            return item?.category?.parent?.id === categoryId;
          });
        } else {
          results = itemsData.filter((item) => {
            return item?.category?.id === subCategoryId;
          });
        }
        setItems(results);
        setFilterItems(results);
        setSearchItems(itemsData);
        toast.update(toastId, {
          render: "درخواست با موفقیت ارسال شد",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          closeOnClick: true,
        });
      } catch (error) {
        console.log("Error fetching ctegories:", error);
      }
    }
    fetchData();
  };
  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      let results = filterItems.filter((item) => {
        return item?.title.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setItems(results);
    } else {
      setItems(filterItems);
    }
  };
  const filterWithCategory = (category) => {
    setCategoryId(category);
    setSubCategoryId(0);
    let results = searchItems.filter((item) => {
      return item?.category?.parent?.id === category;
    });
    setItems(results);
    setFilterItems(results);
  };
  const filterWithSubCategory = (subCategory) => {
    setSubCategoryId(subCategory);
    let results;
    if (subCategory === 0) {
      results = searchItems.filter((item) => {
        return item?.category?.parent?.id === categoryId;
      });
    } else {
      results = searchItems.filter((item) => {
        return item?.category?.id === subCategory;
      });
    }
    setItems(results);
    setFilterItems(results);
  };

  return (
    <>
      <Container>
        <SearchWrapper>
          <Search filter={filter} />
        </SearchWrapper>
        <ListWrapper>
          {loading ? (
            <div className="loadingList">
              <div className="spinner"></div>
            </div>
          ) : (
            <List
              handleOpenModal={handleOpenModal}
              data={items}
              categories={categoriesItem}
              filterWithCategory={filterWithCategory}
              filterWithSubCategory={filterWithSubCategory}
            />
          )}
        </ListWrapper>
        <ModalOperations
          showModalOperations={showModalOperations}
          detailItem={detailItem}
          handleCloseModal={handleCloseModal}
          handleOpenModal={handleOpenModal}
          handleAddNew={handleAddNew}
          deleteImage={deleteImage}
          addImage={addImage}
        ></ModalOperations>
      </Container>
    </>
  );
};

export default Products;
