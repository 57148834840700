import Joi from "joi";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import axiosConfig from "../../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";

import {
  Body,
  Container,
  Header,
  TitleHead,
  DivItems,
  Btn,
  BtnDiv,
  DivIconHead,
  IconHead,
  ListItems,
  DivHeader,
  Lable,
  DivList,
  Price,
  DivTitleHead,
  TitleCategoryHead,
  DivRow,
  DivInputDescription,
  Helper,
  CoverImg,
  Img,
  DivDelete,
  HeaderDiscount,
} from "./OperationsElement";
import icon from "../../../Images/headerIcons/products.svg";
import iconDiscount from "../../../Images/headerIcons/discount.svg";
import iconSelect from "../../../Images/selectImg/product.svg";
import hoverIconSelect from "../../../Images/selectImg/productHover.svg";
import iconDelete from "../../../Images/deleteIcon.svg";

import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import "react-multi-date-picker/styles/layouts/mobile.css";
import moment from "jalali-moment";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

import { useTranslation } from "react-i18next";
import {
  BtnOff,
  DivHalfInput,
  DivInput,
  DivMainHalfInput,
  DivOff,
  DivToggleButton,
  Input,
  ToggleButton,
} from "./Element";
const weekDays = [
  ["شنبه", "شنبه"], //[["نام","نام کوتاه"], ... ]
  ["یکشنبه", "یکشنبه"],
  ["دوشنبه", "دوشنبه"],
  ["سه شنبه", "سه شنبه"],
  ["چهارشنبه", "چهارشنبه"],
  ["پنجشنبه", "پنجشنبه"],
  ["جمعه", "جمعه"],
];

const Operations = ({
  showModalOperations,
  handleAddNew,
  handleCloseModal,
  handleOpenModal,
  detailItem,
  deleteImage,
  addImage,
}) => {
  const [data, setData] = useState({
    company_id: null,
    product_id: null,
    price: "",
    description: "",
    weight: null,
    register_at: "",
    images: [],
    discount_value: "",
    discount_type: "percentage",
    discount_start_at: "",
    discount_end_at: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [showModalDiscount, setShowModalDiscount] = useState(false);
  const [distance_priceWithCommas, setDistancePriceWithCommas] = useState("");
  const [item, setItem] = useState(null);
  const [priceWithCommas, setPriceWithCommas] = useState(null);
  const [priceWithDiscount, setPriceWithDiscount] = useState(null);
  const [discountStartAt, setDiscountStartAt] = useState("");
  const [discountEndAt, setDiscountEndAt] = useState("");

  const [hoverImg, setHoverImg] = useState(iconSelect);
  const [errors, setErrors] = useState({});

  const { t } = useTranslation();
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    setShowModal(showModalOperations);
    if (showModalOperations) {
      if (!detailItem?.images) {
        setData({
          ...data,
          product_id: detailItem?.id,
          company_id: parseInt(localStorage.getItem("companyId")),
          description: "",
          images: [],
          discount_value: "",
          discount_type: "percentage",
          discount_start_at: "",
          discount_end_at: "",
          price: detailItem?.price,
          weight: detailItem?.weight,
          register_at: detailItem?.register_at,
        });
      } else {
        let imagesArray = [];
        console.log(detailItem?.images);
        detailItem?.images.forEach((image) => {
          imagesArray.push({ id: image?.id, value: image?.image });
        });
        setData({
          ...data,
          product_id: detailItem?.id,
          company_id: parseInt(localStorage.getItem("companyId")),
          description: detailItem?.last_detail?.description,
          images: imagesArray,
          discount_value: "",
          discount_type: "percentage",
          discount_start_at: "",
          discount_end_at: "",
          price: detailItem?.price,
          weight: detailItem?.weight,
          register_at: detailItem?.register_at,
        });
      }
      setItem(detailItem);
    }
    console.log(data);
  }, [detailItem, showModalOperations]);
  const schema = Joi.object({
    company_id: Joi.number().integer().required(),
    product_id: Joi.number().integer().required(),
    price: Joi.number().integer().required(),
    description: [Joi.string().optional(), Joi.allow("")],
    weight: Joi.number().integer().required(),
    register_at: Joi.string().optional(),
    images: Joi.array().min(0).required(),
    discount_value: [Joi.string().optional(), Joi.allow("")],
    discount_type: [Joi.string().optional(), Joi.allow("")],
    discount_start_at: [Joi.string().optional(), Joi.allow("")],
    discount_end_at: [Joi.string().optional(), Joi.allow("")],
  });
  const CloseModal = () => {
    handleCloseModal("modalOperations");
    setData({
      company_id: null,
      product_id: null,
      price: "",
      description: "",
      weight: null,
      register_at: "",
      images: [],
      discount_value: "",
      discount_type: "percentage",
      discount_start_at: "",
      discount_end_at: "",
    });
    setPriceWithCommas(null);
    setDiscountStartAt("");
    setDiscountEndAt("");
  };
  const openModalDiscount = () => {
    setShowModalDiscount(true);
  };
  const closeModalDiscount = () => {
    setShowModalDiscount(false);
  };
  const handleToggleButton = () => {
    if (data.discount_type === "percentage") {
      setData({
        ...data,
        discount_type: "fixed",
        discount_value: "",
      });
      setDistancePriceWithCommas("");
    } else {
      setData({
        ...data,
        discount_type: "percentage",
        discount_value: "",
      });
    }
    setErrors({});
  };

  const handleNumber = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  const handleDiscountNumber = (e, type) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    } else {
      if (type === "percentage") {
        if (data?.price === "") {
          console.log(data?.price);
          console.log(e.currentTarget.value);
          setPriceWithDiscount(e.currentTarget.value);
        } else {
          console.log(data?.price);
          console.log(e.currentTarget.value);
          setPriceWithDiscount(e.currentTarget.value);
        }
        setPriceWithDiscount();
      }
    }
  };
  const handleChangeDistancePrice = (e) => {
    const price = addCommas(removeNonNumeric(e.target.value));
    setDistancePriceWithCommas(price);
    setData({ ...data, discount_value: price.replaceAll(",", "") });
  };
  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

  const handleChangePrice = (e) => {
    const price = addCommas(removeNonNumeric(e.target.value));
    console.log(data);
    setPriceWithCommas(price);
    setData({ ...data, [e.currentTarget.id]: price.replaceAll(",", "") });
  };
  const handleChange = (e) => {
    setData({ ...data, [e.currentTarget.id]: e.currentTarget.value });
    console.log(data);
  };

  const selectFile = () => {
    document.getElementById("fname").click();
  };
  const onSelectImage = (e) => {
    if (e.currentTarget && e.currentTarget.files[0]) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const base64Image = e.target.result;
        // console.log(detailItem.product);
        // let dataAddImg = {
        //   product_id: detailItem?.id,
        //   company_id: parseInt(localStorage.getItem("companyId")),
        //   image: base64Image,
        // };
        // console.log(dataAddImg);
        // let isOkResponse;
        // const toastId = toast.loading(t("adding image ..."));
        // const token = localStorage.getItem("tokenDeliveryCompanyRoysa");
        // const config = {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //     "Access-Control-Allow-Origin": "*",
        //   },
        // };
        // try {
        //   console.log("first");
        //   await axiosConfig.post(
        //     "/company_product/image/store",
        //     dataAddImg,
        //     config
        //   );
        //   isOkResponse = true;
        // } catch (error) {
        //   isOkResponse = false;
        //   console.log(
        //     "Error response from server:",
        //     error.response.data.status
        //   );
        //   toast.update(toastId, {
        //     render: t("unable to connect to server"),
        //     type: "error",
        //     isLoading: false,
        //     autoClose: 3000,
        //     closeOnClick: true,
        //   });
        // }
        // if (isOkResponse) {
        //   addImage(toastId, detailItem.id);
        // }

        let data_array = data?.images;

        let my_object = {};
        my_object.id = null;
        my_object.value = base64Image;
        data_array.push(my_object);
        console.log(data_array);

        setData({ ...data, images: data_array });
      };
      reader.readAsDataURL(e.currentTarget.files[0]);

      console.log(data);
    }
  };
  const handleDeleteImage = async (imageId, i) => {
    if (!imageId) {
      let myArray = data.images;
      myArray = myArray.filter((item, index) => index !== i);
      setData({ ...data, images: myArray });
      // data?.images?.splice(index);
    } else {
      let isOkResponse;
      const toastId = toast.loading(t("deleting image ..."));
      const token = localStorage.getItem("tokenDeliveryCompanyRoysa");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      };
      try {
        await axiosConfig.delete(
          "/company_product/image/delete/" + imageId,
          config
        );
        isOkResponse = true;
      } catch (error) {
        isOkResponse = false;
        console.log("Error response from server:", error.response.data.status);
        toast.update(toastId, {
          render: t("unable to connect to server"),
          type: "error",
          isLoading: false,
          autoClose: 3000,
          closeOnClick: true,
        });
      }
      if (isOkResponse) {
        deleteImage(toastId, detailItem.id);
      }
    }
  };

  const startHover = () => {
    setHoverImg(hoverIconSelect);
  };
  const finishHover = () => {
    setHoverImg(iconSelect);
  };
  const toEnglishDigits = (str) => {
    // convert persian digits [۰۱۲۳۴۵۶۷۸۹]
    var e = "۰".charCodeAt(0);
    str = str.replace(/[۰-۹]/g, function (t) {
      return t.charCodeAt(0) - e;
    });

    // convert arabic indic digits [٠١٢٣٤٥٦٧٨٩]
    e = "٠".charCodeAt(0);
    str = str.replace(/[٠-٩]/g, function (t) {
      return t.charCodeAt(0) - e;
    });
    return str;
  };
  const handleChangeStartDate = (val) => {
    setDiscountStartAt(val);
    const dateSelected = val.format().split(" | ");
    const jalaliDate = toEnglishDigits(dateSelected[0]);
    const jalaliTime = toEnglishDigits(dateSelected[1]);
    const gregorianDate = moment(jalaliDate, "jYYYY/jMM/jDD")
      .locale("en")
      .format("YYYY-MM-DD");
    setData({ ...data, discount_start_at: gregorianDate + " " + jalaliTime });
  };
  const handleChangeEndDate = (val) => {
    setDiscountEndAt(val);
    const dateSelected = val.format().split(" | ");
    const jalaliDate = toEnglishDigits(dateSelected[0]);
    const jalaliTime = toEnglishDigits(dateSelected[1]);
    const gregorianDate = moment(jalaliDate, "jYYYY/jMM/jDD")
      .locale("en")
      .format("YYYY-MM-DD");
    setData({ ...data, discount_end_at: gregorianDate + " " + jalaliTime });
  };
  const handleFinalPrice = (lastPrice, newPrice) => {
    let price = "";
    if (newPrice === "") {
      price = numberWithCommas(lastPrice);
    } else {
      price = numberWithCommas(newPrice);
    }
    return price + " تومان";
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    const updatedData = { ...data, register_at: formattedTime };

    const errors = validate(updatedData);
    setErrors({ errors: errors || {} });
    if (errors) return;
    doSubmit(updatedData);
  };
  const validate = (updatedData) => {
    const result = schema.validate(updatedData, { abortEarly: false });
    let errorMessage = "";
    if (!result.error) return null;
    if (result.error.details[0].path[0] === "price") {
      errorMessage = "قیمت نمی تواند خالی باشد";
    } else if (result.error.details[0].path[0] === "weight") {
      errorMessage = "میزان موجودی نمی تواند خالی باشد";
    } else if (result.error.details[0].path[0] === "images") {
      errorMessage = "حداقل یک تصویر برای محصول انتخاب کنید";
    }
    toast.warning(errorMessage);
    console.log(result.error);
    const errors = {};
    for (const item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };
  const doSubmit = async (updatedData) => {
    let isOkResponse;
    if (data.discount_value !== "" && data.discount_start_at === "") {
      toast.warning("به دلیل اعمال تخفیف تاریخ شروع تخفیف نمی تواند خالی باشد");
    } else if (data.discount_value !== "" && data.discount_end_at === "") {
      toast.warning(
        "به دلیل اعمال تخفیف تاریخ پایان تخفیف نمی تواند خالی باشد"
      );
    } else {
      const toastId = toast.loading(t("sending request ..."));
      const token = localStorage.getItem("tokenDeliveryCompanyRoysa");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      };

      try {
        await axiosConfig.post("/company/product/store", updatedData, config);
        isOkResponse = true;
      } catch (error) {
        isOkResponse = false;
        console.log("Error response from server:", error.response.data.status);
        toast.update(toastId, {
          render: t("unable to connect to server"),
          type: "error",
          isLoading: false,
          autoClose: 3000,
          closeOnClick: true,
        });
      }
      if (isOkResponse) {
        handleAddNew(toastId);
        CloseModal();
      }
    }
  };
  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={CloseModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <Container>
          <Header>
            <DivHeader>
              <DivIconHead>
                <IconHead
                  haveLogo={item?.image ? true : false}
                  src={item?.image ? item?.image : icon}
                />
              </DivIconHead>
              <DivTitleHead>
                <TitleHead>{item?.title}</TitleHead>
                <TitleCategoryHead>
                  {item?.category?.parent?.title +
                    " _ " +
                    item?.category?.title}
                </TitleCategoryHead>
              </DivTitleHead>
            </DivHeader>
          </Header>
          <Body>
            <DivRow>
              <DivInput>
                <Lable>قیمت واحد (تومان)</Lable>
                <Input
                  id="price"
                  onChange={handleChangePrice}
                  onKeyPress={handleNumber}
                  value={priceWithCommas}
                />
                {item?.last_detail && (
                  <Helper>
                    قیمت قبلی :
                    <Price>
                      {numberWithCommas(item?.last_detail?.price)} تومان
                    </Price>
                  </Helper>
                )}
              </DivInput>
              <DivInput>
                <Lable>{"میزان موجودی (" + item?.measure_unit + ")"}</Lable>
                <Input
                  id="weight"
                  onChange={handleChange}
                  onKeyPress={handleNumber}
                  value={data.weight}
                />
                {item?.last_detail && (
                  <Helper>
                    موجودی قبلی :
                    <Price>
                      {" " +
                        item?.last_detail?.weight +
                        " " +
                        item?.measure_unit}
                    </Price>
                  </Helper>
                )}
              </DivInput>
              <DivInput>
                <Lable>تخفیف</Lable>
                <DivOff>
                  <BtnOff onClick={openModalDiscount}>اعمال تخفیف</BtnOff>
                </DivOff>
                {item?.last_detail &&
                  !item?.last_detail?.discount_type === "none" && (
                    <Helper>
                      تخفیف قبلی :
                      <Price>
                        {(item?.last_detail?.discount_type === "fixed" &&
                          numberWithCommas(item?.last_detail?.discount_value) +
                            " تومان") ||
                          (item?.last_detail?.discount_type === "percentage" &&
                            numberWithCommas(
                              item?.last_detail?.discount_value
                            ) + " درصد")}
                      </Price>
                    </Helper>
                  )}
              </DivInput>
            </DivRow>
            <DivRow>
              <DivInputDescription>
                <Lable>توضیحات شرکت شما</Lable>
                <Input
                  id="description"
                  onChange={handleChange}
                  value={data.description}
                />
              </DivInputDescription>
            </DivRow>
            <DivItems>
              <Lable>عکس محصول</Lable>
              <ListItems>
                <DivList>
                  <CoverImg
                    onClick={selectFile}
                    onMouseEnter={startHover}
                    onMouseLeave={finishHover}
                  >
                    <Img src={hoverImg} />
                  </CoverImg>
                  <input
                    accept="image/png, image/jpeg"
                    hidden
                    type="file"
                    id="fname"
                    name="fname"
                    onChange={onSelectImage}
                  ></input>
                  {data?.images?.map((item, index) => (
                    <CoverImg isImage>
                      <Img isImage src={item?.value} />
                      <DivDelete
                        onClick={() => handleDeleteImage(item?.id, index)}
                      >
                        <Img src={iconDelete} />
                      </DivDelete>
                    </CoverImg>
                  ))}
                </DivList>
              </ListItems>
            </DivItems>
            <DivRow>
              <DivInput />
              <DivInput />
              <BtnDiv btnPadding={true}>
                <Btn okBtn onClick={handleSubmit}>
                  {t("send request")}
                </Btn>
                <Btn onClick={CloseModal}>{t("cancel")}</Btn>
              </BtnDiv>
            </DivRow>
          </Body>
        </Container>
      </Modal>
      <Modal
        isOpen={showModalDiscount}
        contentLabel="onRequestClose Example"
        onRequestClose={closeModalDiscount}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <Container>
          <HeaderDiscount>
            <DivIconHead operation="ACCEPT">
              <IconHead src={iconDiscount} />
            </DivIconHead>
            <TitleHead>تخفیف</TitleHead>
          </HeaderDiscount>
          <Body>
            <DivInput>
              <DivToggleButton onClick={handleToggleButton}>
                <ToggleButton active={data.discount_type === "percentage"}>
                  {t("percent")}
                </ToggleButton>
                <ToggleButton active={data.discount_type === "fixed"}>
                  {t("amount")}
                </ToggleButton>
              </DivToggleButton>
            </DivInput>
            <DivInput>
              <Lable>
                میزان تخفیف (
                {data.discount_type === "percentage" ? "%" : "تومان"})
              </Lable>
              {data.discount_type === "percentage" ? (
                <Input
                  id="discount_value"
                  onChange={handleChange}
                  value={data.discount_value}
                  onKeyPress={(e) => {
                    handleDiscountNumber(e, "percentage");
                  }}
                  maxLength={"2"}
                />
              ) : (
                <Input
                  id="distance_price"
                  onChange={handleChangeDistancePrice}
                  value={distance_priceWithCommas}
                  onKeyPress={(e) => {
                    handleDiscountNumber(e, "fixed");
                  }}
                />
              )}
              {/* {item?.last_detail && (
                <Helper>
                  قیمت نهایی :
                  <Price>
                    {handleFinalPrice(item?.last_detail?.price, data?.price)}
                  </Price>
                </Helper>
              )} */}
            </DivInput>
            <DivInput>
              <Lable>زمان شروع تخفیف</Lable>
              <DatePicker
                render={<Input calendarDefault />}
                value={discountStartAt}
                weekDays={weekDays}
                calendar={persian}
                locale={persian_fa}
                className="rmdp-mobile"
                monthYearSeparator=" "
                format="YYYY/MM/DD | HH:mm:ss"
                plugins={[<TimePicker position="bottom" />]}
                mobileLabels={{
                  OK: "تایید",
                  CANCEL: "انصراف",
                }}
                onChange={(val) => {
                  handleChangeStartDate(val);
                }}
              />
            </DivInput>
            <DivInput>
              <Lable>زمان پایان تخفیف</Lable>
              <DatePicker
                render={<Input calendarDefault />}
                value={discountEndAt}
                weekDays={weekDays}
                calendar={persian}
                locale={persian_fa}
                className="rmdp-mobile"
                monthYearSeparator=" "
                format="YYYY/MM/DD | HH:mm:ss"
                plugins={[<TimePicker position="bottom" />]}
                mobileLabels={{
                  OK: "تایید",
                  CANCEL: "انصراف",
                }}
                onChange={(val) => {
                  handleChangeEndDate(val);
                }}
              />
            </DivInput>
            <BtnDiv btnPadding={true}>
              <Btn okBtn onClick={closeModalDiscount}>
                {t("accept")}
              </Btn>
              <Btn onClick={closeModalDiscount}>{t("cancel")}</Btn>
            </BtnDiv>
          </Body>
        </Container>{" "}
      </Modal>
    </>
  );
};

export default Operations;
