import React, { Component } from "react";
import { Container, ListWrapper } from "./ContainerElements";
import { Outlet } from "react-router";

class ContainerSoftware extends Component {
  state = {};

  render() {
    return (
      <>
        <Container>
          <ListWrapper>
            <Outlet/>
          </ListWrapper>
        </Container>
      </>
    );
  }
}

export default ContainerSoftware;
