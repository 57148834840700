import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import Login from "../../Components/Login";

const languages = [
  { code: "en", name: "English", dir: "ltr" },
  { code: "fa", name: "فارسی" },
];

const LoginPage = () => {
  const currentLanguageCode = cookies.get("i18next") || "fa";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  const { t } = useTranslation();
  useEffect(() => {
    document.body.dir = currentLanguage.dir || "rtl";
    document.getElementsByClassName("Toastify__toast-body").dir =
      currentLanguage.dir || "rtl";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  return (
    <>
      <Login />
    </>
  );
};

export default LoginPage;
