import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import HomePage from "./Pages";
import Login from "./Pages/Login";

import Products from "./Pages/Products";
import ListProducts from "./Pages/Products/List";

import Reports from "./Pages/Reports";
import ListReports from "./Pages/Reports/List";

import Invoices from "./Pages/Invoices";
import ListInvoices from "./Pages/Invoices/List";

import Roles from "./Pages/Roles";
import ListRoles from "./Pages/Roles/List";

import Sliders from "./Pages/Sliders";
import ListSliders from "./Pages/Sliders/List";

import Users from "./Pages/Users";
import ListUsers from "./Pages/Users/List";

import { useEffect } from "react";

function App() {
  useEffect(() => {
    if (localStorage.getItem("tokenDeliveryCompanyRoysa") === null) {
      localStorage.setItem("tokenDeliveryCompanyRoysa", "");
    }
  });

  window.onbeforeunload = function () {
    window.setTimeout(function () {
      window.location = "/";
    }, 0);
    window.onbeforeunload = null; // necessary to prevent infinite loop, that kills your browser
  };
  setInterval(function () {
    localStorage.setItem("tokenDeliveryCompanyRoysa", "");
    window.location.replace("/login");
  }, 86400000);

  const token = localStorage.getItem("tokenDeliveryCompanyRoysa");
  const redirectPath =
    token === "" || localStorage.length === 0 ? "login" : "deliveryCompany";
  return (
    <Router className="App">
      <Routes>
        <Route path="login" element={<Login />} exact />
        <Route path="deliveryCompany" element={<HomePage />} exact>
          <Route path="products" element={<Products />} exact>
            <Route path="list" element={<ListProducts />} exact />
          </Route>
          <Route path="reports" element={<Reports />} exact>
            <Route path="list" element={<ListReports />} exact />
          </Route>
          <Route path="invoices" element={<Invoices />} exact>
            <Route path="list" element={<ListInvoices />} exact />
          </Route>
          <Route path="roles" element={<Roles />} exact>
            <Route path="list" element={<ListRoles />} exact />
          </Route>
          <Route path="sliders" element={<Sliders />} exact>
            <Route path="list" element={<ListSliders />} exact />
          </Route>
          <Route path="users" element={<Users />} exact>
            <Route path="list" element={<ListUsers />} exact />
          </Route>
        </Route>
        <Route path="/" element={<Navigate to={redirectPath} />} exact></Route>
        <Route path="*" element={<Navigate to={redirectPath} />} exact></Route>
      </Routes>
    </Router>
  );
}

export default App;
